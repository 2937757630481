import _ from "lodash";

import axios from "@/http/axios";
import productsModule from "@/store/modules/products";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  nextSubscriptionAndRedemption: undefined,
};

const getters = {
  ...productsModule.getters,
  getNextSubscriptionAndRedemption: (state) => {
    return state.nextSubscriptionAndRedemption;
  },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_NEXT_SUBSCRIPTION_AND_REDEMPTION_SUCCESS: (state, { data }) => {
    state.nextSubscriptionAndRedemption = data;
  },
};

const actions = {
  ...productsModule.actions,
  async fetchNextSubscriptionAndRedemption({ commit }, { productId }) {
    try {
      const response = await axios.get(
        `/api/products/${productId}/next-subscription-and-redemption`
      );
      commit("FETCH_NEXT_SUBSCRIPTION_AND_REDEMPTION_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_NEXT_SUBSCRIPTION_AND_REDEMPTION_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
